<template>
  <a-form-model
    :hideRequiredMark="true"
    :model="model"
    :rules="rules"
    :wrapper-col="{ span: 24 }"
    ref="form"
    class="login-container-form"
  >
    <a-form-model-item prop="accountName">
      <a-input
        size="large"
        id="retrive-pass-phone-accountName"
        v-model="model.accountName"
        autocomplete="new-password"
        placeholder="请输入手机号"
      >
        <a-icon slot="prefix" type="mobile" />
      </a-input>
    </a-form-model-item>
    <!-- </a-col>
    </a-row> -->
    <a-form-model-item prop="captcha">
      <a-input
        size="large"
        id="retrive-pass-phone-captcha"
        autocomplete="new-password"
        v-model="model.captcha"
        placeholder="请输入验证码"
      >
        <a-icon slot="prefix" type="search" />
        <template slot="suffix">
          <a-button
            type="link"
            v-if="!sendCaptchaStatus.s"
            @click="sendCaptcha"
            :disabled="!captchaBtnEnabled"
            >获取验证码</a-button
          >
          <a-button disabled type="link" v-if="sendCaptchaStatus.s"
            >重新获取({{ sendCaptchaStatus.time }}秒后)</a-button
          >
        </template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <a-input-password
        size="large"
        id="retrive-pass-phone-password"
        v-model="model.password"
        autocomplete="new-password"
        placeholder="设置新密码(8到20个字包括数字和字母)"
      >
        <a-icon slot="prefix" type="lock"
      /></a-input-password>
    </a-form-model-item>
    <a-form-model-item prop="password2">
      <a-input-password
        size="large"
        v-model="model.password2"
        id="retrive-pass-phone-password2"
        autocomplete="new-password"
        placeholder="再次输入密码(8到20个字包括数字和字母)"
      >
        <a-icon slot="prefix" type="lock"
      /></a-input-password>
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        size="large"
        block
        :loading="formLoading"
        @click="doSubmit"
      >
        提交
      </a-button>
    </a-form-model-item>
    <!-- <a-form-model-item style="float: right">
      已有帐号?
      <a-button type="link" @click="goLogin"> 登录 </a-button>
    </a-form-model-item> -->
  </a-form-model>
</template>
<script>
import { RegExpHelper } from "@/libs/regexp";
import { FormMixin } from "@/views/mixins/form";
export default {
  name: "retrieve-pass-captcha-form-model",
  props: {
    formLoading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      model: {
        areaCode: "+86",
        accountName: "",
        captcha: "",
        rememberMe: false,
        agree: true,
        password: "",
        password2: "",
      },
      rules: {
        accountName: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(new Error("手机号不能为空"));
                this.captchaBtnEnabled = false;
              }
              if (!RegExpHelper.phone.test(value)) {
                this.captchaBtnEnabled = false;
                callback(new Error("请输入正确的手机号"));
              } else {
                this.captchaBtnEnabled = true;
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === "") {
                callback(new Error("验证码不能为空"));
              }
              if (!RegExpHelper.captcha.test(value)) {
                callback(new Error("验证码必须为6位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        agree: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请勾选同意协议内容"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "新密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value == null || value.length < 8 || value.length > 20) {
                callback(new Error("需要设置8-20字符内,包含数字和字母"));
              }
              if (!RegExpHelper.password.test(value)) {
                callback(new Error("需要设置8-20字符内,包含数字和字母"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        password2: [
          {
            required: true,
            message: "再次输入密码不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value == null || value.length < 8 || value.length > 20) {
                callback(new Error("需要设置8-20字符内,包含数字和字母"));
              }
              if (!RegExpHelper.password.test(value)) {
                callback(new Error("需要设置8-20字符内,包含数字和字母"));
              }
              if (value !== this.model.password) {
                callback(new Error("两次密码输入不一致"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
};
</script>