<template>
  <AccountLayout>
    <a-card hoverable class="account-right-container">
      <div class="login-container">
        <a-space class="login-container-header">
          <a-button shape="circle" icon="arrow-left" @click="goLogin" />
          <div class="login-container-header-title"
          >
            <template v-if="mode === 1"> 手机号验证码找回 </template>
            <template v-if="mode === 2"> 邮箱验证码找回 </template>
          </div>
        </a-space>
        <phone-captcha-form
          ref="phoneCaptchForm"
          v-if="mode === 1"
          @sendCaptcha="doVerifyPhoneCaptcha"
          @submit="retrievePassPhoneWithCaptcha"
          :formLoading="formLoading"
        ></phone-captcha-form>
        <email-captcha-form
          v-if="mode === 2"
          ref="emailCaptchForm"
          @sendCaptcha="doVerifyEmailCaptcha"
          @submit="retrievePassEmailWithCaptcha"
          :formLoading="formLoading"
        ></email-captcha-form>
        <a-button
          type="link"
          :block="true"
          @click="switchMode"
          v-if="mode === 1"
          >邮箱验证码找回</a-button
        >
        <a-button
          type="link"
          :block="true"
          @click="switchMode"
          v-if="mode === 2"
          >手机号验证码找回</a-button
        >
      </div>
    </a-card>
    <template slot="outside">
      <Verify
        @success="retrievePassPhoneSendCaptcha"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="phoneCaptchaVerify"
      ></Verify>
      <Verify
        @success="retrievePassEmailSendCaptcha"
        @error="verifyErrorHandler"
        :mode="'pop'"
        :captchaType="'blockPuzzle'"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="emailCaptchaVerify"
      ></Verify>
    </template>
  </AccountLayout>
</template>



<script>
import Verify from "@/components/verifition";
import PhoneCaptchaForm from "./components/phone-captcha-form";
import EmailCaptchaForm from "./components/email-captcha-form";
import { loginAPI } from "@/api/login";
import { deepCopy } from "@/libs/utils";
import md5 from "md5";
export default {
  name: "retrieve-pass",
  props: {
    msg: String,
  },
  data() {
    return {
      formFields: {},
      formLoading: false,
      mode: 1,
    };
  },
  components: {
    Verify,
    PhoneCaptchaForm,
    EmailCaptchaForm,
  },
  mounted() {},
  methods: {
    goLogin() {
      this.$router.push({ name: "login" });
    },
    doVerifyPhoneCaptcha(data) {
      this.$refs.phoneCaptchaVerify.show();
      this.formFields = data;
    },
    doVerifyEmailCaptcha(data) {
      this.$refs.emailCaptchaVerify.show();
      this.formFields = data;
    },
    verifyErrorHandler(res) {
      this.$message.error(res.repMsg + ",错误码:[" + res.repCode + "]");
    },
    analyzerLoginRes(res) {
      if (res.status) {
        this.$message.success("设置成功!");
        this.$router.push({ name: "login" });
      } else {
        this.$message.error(res.msg + ",错误码:[" + res.code + "]");
      }
    },
    retrievePassPhoneSendCaptcha(verifyParams) {
      this.$refs.phoneCaptchForm.doRegisterSendCaptchStatus();
      this.formFields.authCaptcha = verifyParams.captchaVerification;
      loginAPI
        .retrievePassPhoneSendCaptcha(this.formFields)
        .then((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        })
        .catch((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        });
    },
    retrievePassPhoneWithCaptcha(data) {
      this.formLoading = true;
      let request = deepCopy(data);
      request.authCaptcha = this.formFields.authCaptcha;
      request.password = md5(this.formFields.password);
      request.password2 = md5(this.formFields.password2);
      loginAPI
        .retrievePassPhoneWithCaptcha(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    retrievePassEmailSendCaptcha(verifyParams) {
      this.$refs.emailCaptchForm.doRegisterSendCaptchStatus();
      this.formFields.authCaptcha = verifyParams.captchaVerification;
      loginAPI
        .retrievePassEmailSendCaptcha(this.formFields)
        .then((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        })
        .catch((res) => {
          if (!res.status) {
            this.$message.error(res.msg + ",错误码:[" + res.code + "]");
          }
        });
    },
    retrievePassEmailWithCaptcha(data) {
      this.formLoading = true;
      let request = deepCopy(data);
      request.authCaptcha = this.formFields.authCaptcha;
      request.password = md5(this.formFields.password);
      request.password2 = md5(this.formFields.password2);
      loginAPI
        .retrievePassEmailWithCaptcha(request)
        .then((res) => {
          this.analyzerLoginRes(res);
          this.formLoading = false;
        })
        .catch((e) => {
          this.$message.error("调用失败", e);
          this.formLoading = false;
        });
    },
    switchMode() {
      this.mode = this.mode === 1 ? 2 : 1;
    },
  },
};
</script>
